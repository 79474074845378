import React, { useContext } from 'react';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import {
  DownloadOutlined,
  ReadOutlined,
  FileDoneOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import './CollectionCard.less';
import { globalStyles } from '../../styles/variables';
import { StrapiCollectionType } from '../../types';

const CollectionCard = (props: StrapiCollectionType) => {
  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);
  return (
    <li className="collection-card">
      {!authenticated && (
        <>
          <div className="bg-wrapper"></div>
          <div className="collection-card-locker">
            <LockOutlined />
          </div>
        </>
      )}
      <h3>{props.title}</h3>
      <div className="card-section-wrapper">
        <p className="card-paragraph">
          <ReadOutlined
            style={{ color: `${globalStyles.colors[props.color]}` }}
          />
          <span className="card-paragraph-description">{props.category}</span>
        </p>
        <Button
          className="download-btn"
          type="primary"
          icon={<DownloadOutlined />}
          href={authenticated ? props.exercisesPdf : null}
          target="_blank"
          download
        >
          Pobierz (PDF)
        </Button>
      </div>
      <div className="card-section-wrapper">
        <p className="card-paragraph">
          <FileDoneOutlined
            style={{ color: `${globalStyles.colors[props.color]}` }}
          />
          <span className="card-paragraph-description">Klucz odpowiedzi</span>
        </p>
        <Button
          className="ant-btn-primary"
          type="primary"
          icon={<DownloadOutlined />}
          href={authenticated ? props.answersPdf : null}
          target="_blank"
          download
        >
          Pobierz (PDF)
        </Button>
      </div>
    </li>
  );
};

export default CollectionCard;
