import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CardData from '../CardData/CardData';

const SpreadsheetsSection = () => {
  const cardsTitle = 'Arkusze';
  const data = useStaticQuery(graphql`
    query spreadsheetsData {
      allStrapiCollection(filter: { category: { eq: "Arkusz" } }) {
        nodes {
          title {
            name
            themeColor
          }
          answersPdf {
            localFile {
              url
            }
          }
          category
          exercisesPdf {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  const spreadsheetsData = data.allStrapiCollection.nodes;
  return <CardData cardsTitle={cardsTitle} cardData={spreadsheetsData} />;
};

export default SpreadsheetsSection;
