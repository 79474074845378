import React from 'react';

const CollectionHeaderAuth = () => {
  return (
    <p className="collections-page-info">
      Wszystkie materiały dodatkowe dostępne są do pobrania w formacie PDF
    </p>
  );
};

export default CollectionHeaderAuth;
