import React, { useContext } from 'react';
import { GlobalStateContext } from '../context/GlobalContextProvider';
import { Layout } from '../components/Layout/Layout';
import { Breadcrumb, Divider } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './collections.less';
import books from './../assets/images/books.svg';
import CollectionSetsSection from '../components/CollectionSetsSection/CollectionSetsSection';
import SpreadsheetsSection from '../components/SpreadsheetsSection/SpreadsheetsSection';
import CollectionHeaderAuth from '../components/CollectionHeaderAuth/CollectionHeaderAuth';
import CollectionHeaderUnAuthorised from '../components/CollectionHeaderUnAuthorised/CollectionHeaderUnAuthorised';
import { ROUTES } from '../constants/routes';

const Collections = () => {
  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);
  return (
    <Layout>
      <section className="section-content-container">
        <section className="collections-page">
          <Breadcrumb separator={<ArrowRightOutlined />}>
            <Breadcrumb.Item href={ROUTES.COURSE}>Kurs</Breadcrumb.Item>
            <Breadcrumb.Item href={ROUTES.COLLECTIONS}>
              Zbiory zadań i arkusze
            </Breadcrumb.Item>
          </Breadcrumb>
          <Divider></Divider>
          {authenticated ? (
            <CollectionHeaderAuth />
          ) : (
            <CollectionHeaderUnAuthorised />
          )}
          <img src={books} alt="books" className="collections-page-books" />
          <CollectionSetsSection />
          <SpreadsheetsSection />
        </section>
      </section>
    </Layout>
  );
};

export default Collections;
