import React, { useState } from 'react';
import { Carousel, Typography } from 'antd';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import CollectionCard from '../CollectionCard/CollectionCard';
import './CardData.less';
import { StrapiCollectionType } from '../../types';

type CardDataProps = {
  cardsTitle: string;
  cardData: any;
};

const CardData = ({ cardsTitle, cardData }: CardDataProps) => {
  const carouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { Title } = Typography;
  const { isMobile } = useMediaQuery();
  const [currentCard, setCurrentCard] = useState(0);

  const Cards = cardData.map((card: StrapiCollectionType, index: number) => (
    <CollectionCard
      key={index}
      title={card.title.name}
      color={card.title.themeColor}
      category={card.category}
      exercisesPdf={card.exercisesPdf.localFile.url}
      answersPdf={card.answersPdf.localFile.url}
    />
  ));

  return (
    <>
      <Title level={3}>{cardsTitle}</Title>
      {isMobile ? (
        <Carousel
          {...carouselProps}
          className="collection-carousel-dots"
          afterChange={currentID => setCurrentCard(currentID)}
        >
          {Cards}
        </Carousel>
      ) : (
        <ul className="collection-container">{Cards}</ul>
      )}
    </>
  );
};

export default CardData;
