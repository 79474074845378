import { Button } from 'antd';
import { Link } from 'gatsby';
import React from 'react';
import { ROUTES } from '../../constants/routes';
import './CollectionHeaderUnAuthorised.less';

const CollectionHeaderUnAuthorised = () => {
  return (
    <header className="collections-page-header">
      <h3>
        Chcesz sprawdzić wiedzę w <span>praktyce?</span>
      </h3>
      <p>
        Wszystkie materiały dodatkowe możesz zdobyć wraz z{' '}
        <Link className="product-link" to={`${ROUTES.PRODUCTS}?2`}>
          Pakietem Plus
        </Link>{' '}
        lub{' '}
        <Link className="product-link" to={`${ROUTES.PRODUCTS}?3`}>
          Pakietem Premium
        </Link>
        .
      </p>
      <Button href={ROUTES.PRODUCTS}>Sprawdź produkty</Button>
    </header>
  );
};

export default CollectionHeaderUnAuthorised;
