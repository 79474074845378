import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import CardData from '../CardData/CardData';

const CollectionSetsSection = () => {
  const data = useStaticQuery(graphql`
    query collectionSetsData {
      allStrapiCollection(filter: { category: { eq: "Zbiór zadań" } }) {
        nodes {
          title {
            name
            themeColor
          }
          answersPdf {
            localFile {
              url
            }
          }
          category
          exercisesPdf {
            localFile {
              url
            }
          }
        }
      }
    }
  `);

  const collectionSetsData = data.allStrapiCollection.nodes;
  const cardsTitle = 'Zbiór zadań';

  return <CardData cardsTitle={cardsTitle} cardData={collectionSetsData} />;
};

export default CollectionSetsSection;
